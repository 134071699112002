import {useStore} from "vuex";
import {UserRole} from "@/services/AuthenticationService";

export default {

    getSideBarNavMenu() {

        const role = useStore().state.role as UserRole;

        const menu = [];

        menu.push({
            component: 'CNavItem',
            name: 'Dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            badge: {
                color: 'info',
                text: 'NEW',
            },
        });

        if (role === 'APPLICATION_ADMINISTRATOR' || role === "APPLICATION_MANAGER" || role === "COMPANY_ADMINISTRATOR") {

            if (role === "COMPANY_ADMINISTRATOR") {
                menu.push({
                    component: 'CNavItem',
                    name: 'My Company',
                    to: '/company',
                    icon: 'cil-factory',
                });
            } else {
                menu.push({
                    component: 'CNavItem',
                    name: 'Companies',
                    to: '/companies',
                    icon: 'cil-factory',
                });
            }

            menu.push({
                component: 'CNavItem',
                name: 'Users',
                to: '/users',
                icon: 'cil-user',
            });

        }

        if (role !== 'USER') {
            menu.push({
                component: 'CNavItem',
                name: 'Games',
                to: '/games',
                icon: 'cil-gamepad',
            });
        }

        if (role === 'APPLICATION_ADMINISTRATOR' || role === "APPLICATION_MANAGER") {
            menu.push({
                component: 'CNavItem',
                name: 'Game Types',
                to: '/gametypes',
                icon: 'cil-tag',
            });
            menu.push({
                component: 'CNavItem',
                name: 'Game symbol lists',
                to: '/game-symbols',
                icon: 'cil-list',
            });

        }
        return menu;
    },
}