
import {watch} from 'vue'
import {useStore} from 'vuex'
import Interceptors from "@/services/Interceptors";
import emitter from "@/main";
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/vue-pro';
import {Component, Vue} from "vue-facing-decorator";
import GameEditor from "@/views/GameEditor.vue";
import ChangePassword from "@/views/ChangePassword.vue";

@Component(
    {
      components: {
        ChangePassword,
        GameEditor,
        CModal,
        CModalHeader,
        CModalTitle,
        CModalBody,
        CModalFooter,
        CButton,
      },
    }
)
export default class UserLogin extends Vue {

  private showSettings = false;

  mounted() {
    const store = useStore()
    Interceptors();

    watch(store.state, () => {
      store.state.theme === 'dark'
          ? document.body.classList.add('dark-theme')
          : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')

    emitter.on('open-settings', () => this.showSettings = true)
  }
}
