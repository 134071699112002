
import {Component, Prop, Vue, Watch} from "vue-facing-decorator";

import {
  CButton,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSmartTable,
  CTooltip
} from "@coreui/vue-pro";
import GameSymbolService from "@/services/GameSymbolService";
import {GameSymbol} from "@/api/gameSymbol";
import {CIcon} from "@coreui/icons-vue";

@Component(
    {
      components: {
        CButton,
        CIcon,
        CImage,
        CModal,
        CModalHeader,
        CModalTitle,
        CModalBody,
        CFormInput,
        CFormFloating,
        CFormLabel,
        CFormSelect,
        CFormTextarea,
        CModalFooter,
        CSmartTable,
        CTooltip
      },
    }
)
export default class SymbolLookup extends Vue {

  @Prop({default: false})
  public showSuggestions!: boolean;

  @Prop({default: []})
  public gameSymbolSuggestions!: GameSymbol[];

  private symbolFilter = '';
  private selectedSymbol = '-1';
  private selectedSymbolSuggestion = '-1';
  private selectedSymbolSuggestionAuto = '-1';
  private availableSymbols: string[] = [];
  private premiumSymbols: string[] = [];
  private blacklistSymbols: string[] = [];
  private filteredSymbolSuggestions: string [] = [];

  private symbolSuggestionsAuto: string[] = [];
  private symbolSuggestions: string[] = [];
  private premiumSymbolsSuggestions: string[] = [];
  private blacklistSymbolsSuggestions: string[] = [];

  private timerHandleSymbolLookup = 0;

  public reset() {
    this.selectedSymbol = '-1';
    this.selectedSymbolSuggestion = '-1';
    this.selectedSymbolSuggestionAuto = '-1';
    this.symbolSuggestions = [];
    this.premiumSymbols = [];
    this.blacklistSymbols = [];
    this.filteredSymbolSuggestions = [];
    this.symbolSuggestionsAuto = [];
    this.symbolFilter = '';
  }

  public resetFilter() {
    this.symbolFilter = '';
  }

  private showAvailableSymbols(symbolStartsWith: string) {
    if (symbolStartsWith.length > 1) {
      GameSymbolService.showAvailableSymbols(symbolStartsWith)
          .then((data) => {
            this.availableSymbols = data.items.map((gameSymbol: GameSymbol) => gameSymbol.symbol);
            this.premiumSymbols = data.items
                .filter((gameSymbol: GameSymbol) => gameSymbol.attribute === 'PREMIUM')
                .map((gameSymbol: GameSymbol) => gameSymbol.symbol);
            this.blacklistSymbols = data.items
                .filter((gameSymbol: GameSymbol) => gameSymbol.attribute === 'BLACKLIST')
                .map((gameSymbol: GameSymbol) => gameSymbol.symbol);
          })
          .catch((error) => console.log(error))
    } else {
      this.availableSymbols = [];
    }
  }

  @Watch('symbolFilter')
  private onSymbolFilter() {
    clearTimeout(this.timerHandleSymbolLookup)
    if (!this.symbolFilter || this.symbolFilter.length < 2) {
      this.availableSymbols = [];
    } else {
      this.timerHandleSymbolLookup = setTimeout(() => this.showAvailableSymbols(this.symbolFilter), 200)
    }
    const filterChars = [...this.symbolFilter.toUpperCase()];
    this.filteredSymbolSuggestions = this.symbolSuggestions.filter(symbol => filterChars.every((char) => [...symbol].indexOf(char) >= 0));
  }

  @Watch('selectedSymbol')
  private onSelectedSymbol() {
    const symbol = this.availableSymbols[+this.selectedSymbol];
    this.$emit('symbol-selection', symbol);
  }

  @Watch('selectedSymbolSuggestion')
  private onSelectedSymbolSuggestion() {
    const symbol = this.filteredSymbolSuggestions[+this.selectedSymbolSuggestion];
    this.$emit('symbol-selection', symbol);
  }

  @Watch('selectedSymbolSuggestionAuto')
  private onSelectedSymbolSuggestionAuto() {
    const symbol = this.symbolSuggestionsAuto[+this.selectedSymbolSuggestionAuto];
    this.$emit('symbol-selection', symbol);
  }

  @Watch('gameSymbolSuggestions')
  private onGameSymbolSuggestions() {
    this.symbolSuggestionsAuto = this.gameSymbolSuggestions
        .filter((item: GameSymbol) => item.attribute === 'SUGGESTION_AUTO')
        .map((item: GameSymbol) => item.symbol);
    this.symbolSuggestions = this.gameSymbolSuggestions
        .map((item: GameSymbol) => item.symbol)
    this.premiumSymbolsSuggestions = this.gameSymbolSuggestions
        .filter((gameSymbol: GameSymbol) => gameSymbol.attribute === 'PREMIUM')
        .map((gameSymbol: GameSymbol) => gameSymbol.symbol);
    this.blacklistSymbolsSuggestions = this.gameSymbolSuggestions
        .filter((gameSymbol: GameSymbol) => gameSymbol.attribute === 'BLACKLIST')
        .map((gameSymbol: GameSymbol) => gameSymbol.symbol);
    const filterChars = [...this.symbolFilter];
    this.filteredSymbolSuggestions = this.symbolSuggestions.filter(symbol => filterChars.every((char) => [...symbol].indexOf(char) >= 0));
  }

}
