
import {Component, Vue, Watch} from "vue-facing-decorator";
import {CButton, CCallout, CFormFloating, CFormInput, CFormLabel} from "@coreui/vue-pro";
import AuthenticationService from "@/services/AuthenticationService";

@Component(
    {
      components: {
        CCallout,
        CFormFloating,
        CFormInput,
        CFormLabel,
        CButton,
      },
    }
)
export default class PasswordChange extends Vue {

  get changePasswordEnabled() {
    return !!this.currentPassword?.length && !!this.newPassword?.length && this.newPassword === this.newPasswordConfirmation;
  }

  get isCurrentPasswordInvalid() {
    return this.invalidCurrentPassword.length > 0;
  }

  get isNewPasswordInvalid() {
    return this.invalidNewPassword.length > 0;
  }

  get isNewPasswordConfirmationInvalid() {
    return this.invalidNewPasswordConfirmation.length > 0;
  }

  private currentPassword: string | null = null;
  private invalidCurrentPassword = '';
  private newPassword: string | null = null;
  private invalidNewPassword = '';
  private newPasswordConfirmation: string | null = null;
  private invalidNewPasswordConfirmation = '';
  private passwordChangeSuccessful = false;
  private passwordChangeFailed = false;

  private onChangePassword() {

    if (this.changePasswordEnabled) {
      const passwordChangeDto = {
        "oldPassword": this.currentPassword,
        "newPassword": this.newPassword
      }
      AuthenticationService.changePassword(passwordChangeDto)
          .then(() => {
            this.passwordChangeFailed = false;
            this.passwordChangeSuccessful = true;
          })
          .catch((error) => {
            if (error.response.status === 412) {
              this.invalidCurrentPassword = "Invalid password"
            }
            this.passwordChangeSuccessful = false;
            this.passwordChangeFailed = true;
          })
    }
  }

  private resetCallOuts() {
    this.passwordChangeSuccessful = false;
    this.passwordChangeFailed = false;
  }

  @Watch('currentPassword')
  private onCurrentPassword() {
    this.invalidCurrentPassword = '';
    this.resetCallOuts();
  }

  @Watch('newPassword')
  private onNewPassword() {
    this.invalidNewPassword = '';
    this.resetCallOuts();
  }

  @Watch('newPasswordConfirmation')
  private onNewPasswordConfirmation() {
    this.invalidNewPasswordConfirmation = '';
    this.resetCallOuts();
  }

}
