import axios from 'axios';
import {Company} from "@/api/company";
import {baseUrl} from "@/services/Commons";

// const url = 'http://spielomat.ddns.net:8080/producer';
const url = baseUrl + 'company';

export default {

    getAllCompanies() {
        return axios
            .get(url)
            .then(response => response.data);
    },
    getCompany(symbol: string) {
        return axios
            .get(url + '/' + symbol)
            .then(response => response.data);
    },
    addCompany(company: Company) {
        return axios
            .post(url, company)
            .then(response => response.data);
    },
    updateCompany(company: Company) {
        return axios
            .put(url + '/' + company.symbol, company)
            .then(response => response.data);
    },
    deleteCompany(symbol: string) {
        return axios
            .delete(url + '/' + symbol);
    },
    showAvailableSymbols(symbolStartsWith: string) {
        return axios
            .get(url + '/available-symbols?symbolStartsWith=' + symbolStartsWith)
            .then(response => response.data);
    },
};