
import {Component, Prop, Vue, Watch} from "vue-facing-decorator";

import {CFormFloating, CFormInput, CFormLabel, CFormSelect} from "@coreui/vue-pro";
import {Company} from "@/api/company";

@Component(
    {
      components: {
        CFormInput,
        CFormFloating,
        CFormLabel,
        CFormSelect,
      },
    }
)
export default class CompanyLookup extends Vue {

  @Prop({default: []})
  public companies!: Company[];

  private filter = '';
  private selectedCompany = '-1';
  private filteredCompanies: Company [] = [];

  public reset() {
    this.filter = '';
    this.selectedCompany = '-1'
  }

  private companyEntry(company: Company) {
    return company.symbol + ' - ' + company.shortName;
  }

  @Watch('filter', {immediate: true})
  private onFilter() {
    if (this.filter) {
      const filterChars = [...this.filter.toUpperCase()];
      // filter company symbols by chars and company names by string
      const resultSymbol = this.companies.filter(company => filterChars.every((char) => [...company.symbol].indexOf(char) >= 0));
      const resultName = this.companies.filter(company => (company.shortName || '').toUpperCase().indexOf(this.filter.toUpperCase()) >= 0);

      this.filteredCompanies = [...new Set([...resultSymbol, ...resultName])];
    } else {
      this.filteredCompanies = this.companies;
    }

  }

  @Watch('selectedCompany')
  private onSelectedCompany() {
    const company = this.filteredCompanies[+this.selectedCompany];
    this.$emit('company-selection', company);
  }

  @Watch('companies')
  private onCompanies() {
    this.onFilter()
  }
}
