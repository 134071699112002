import axios from "axios";
import {baseUrl} from "@/services/Commons";
import AuthenticationService from "@/services/AuthenticationService";
import {useStore} from "vuex";
import router from "@/router";


const setup = () => {

    const state = useStore().state;
    axios.interceptors.request.use(
        (config) => {

            const token = state.token;
            if (token) {
                if(config.headers) {
                    config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                    // config.headers["x-access-token"] = token; // for Node.js Express back-end
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {

            const originalConfig = err.config;

            if (originalConfig.url !== (baseUrl + 'authenticate') && err.response) {

                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {

                    originalConfig._retry = true;

                    try {
                        await AuthenticationService.refreshToken(state.refreshToken);
                        return axios(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                } else if (err.response.status === 401 && originalConfig._retry) {
                    // Refresh Token expired -> Logout
                    await router.push('/pages/login')
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;