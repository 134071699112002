
import {Component, Prop, Vue, Watch} from "vue-facing-decorator";
import ProducerService from "@/services/CompanyService";
import {Company} from "@/api/company";

import {
  CCollapse,
  CButton, CFormCheck,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSmartTable,
  CTooltip, CFormSwitch
} from "@coreui/vue-pro";
import emitter from "@/main";
import {Game} from "@/api/game";
import GameService from "@/services/GameService";
import GameTypeService from "@/services/GameTypeService";
import {GameType} from "@/api/gameType";
import GameSymbolService from "@/services/GameSymbolService";
import {GameSymbol} from "@/api/gameSymbol";
import {CIcon} from "@coreui/icons-vue";
import SymbolLookUp from "@/views/SymbolLookup.vue";
import SymbolLookup from "@/views/SymbolLookup.vue";
import CompanyLookup from "@/views/CompanyLookup.vue";

@Component(
    {
      components: {
        CCollapse,
        CompanyLookup,
        SymbolLookup,
        SymbolLookUp,
        CButton,
        CFormSwitch,
        CIcon,
        CImage,
        CModal,
        CModalHeader,
        CModalTitle,
        CModalBody,
        CFormCheck,
        CFormInput,
        CFormFloating,
        CFormLabel,
        CFormSelect,
        CFormTextarea,
        CModalFooter,
        CSmartTable,
        CTooltip
      },
    }
)
export default class GameEditor extends Vue {

  @Prop({default: undefined})
  public value!: Game;

  private gameToModify!: Game;

  private gameToAddOrModify: Game = new Game();
  private showAttributesChangedModal = false;

  private symbolFocus = '1';
  private companyFocus = '1';

  private invalidSymbol = '';
  private invalidSecondarySymbol = '';
  private invalidTertiarySymbol = '';
  private invalidName = '';
  private invalidCompany = '';
  private invalidDeveloper = '';
  private invalidGameType = '';
  private invalidLink = '';
  private invalidMaxWin = '';
  private invalidReleased = '';
  private invalidLastUpdate = '';
  private invalidRtpMax = '';
  private invalidRtpMin = '';

  private availableProducers: Company[] = [];
  private suggestions: GameSymbol[] = [];
  private availableGameTypes: GameType[] = [];
  private selectedGameType = '-1';
  private filteredGameTypes: GameType [] = [];

  private rtpValueToAdd = 0;
  private selectedRtpValue = 0;

  private logoFile: File | null = null;
  private logoUploadHover = false;

  private showMore = false;

  get isNewGame() {
    return !this.gameToModify;
  }

  get isSymbolInvalid() {
    return this.invalidSymbol.length > 0;
  }

  get isSecondarySymbolInvalid() {
    return this.invalidSecondarySymbol.length > 0;
  }

  get isTertiarySymbolInvalid() {
    return this.invalidTertiarySymbol.length > 0;
  }

  get isNameInvalid() {
    return this.invalidName.length > 0;
  }

  get isCompanyInvalid() {
    return this.invalidCompany.length > 0;
  }

  get isDeveloperInvalid() {
    return this.invalidDeveloper.length > 0;
  }

  get isGameTypeInvalid() {
    return this.invalidGameType.length > 0;
  }

  get isLinkInvalid() {
    return this.invalidLink.length > 0;
  }

  get isMaxWinInvalid() {
    return this.invalidMaxWin.length > 0;
  }

  get isReleasedInvalid() {
    return this.invalidReleased.length > 0;
  }

  get isLastUpdateInvalid() {
    return this.invalidLastUpdate.length > 0;
  }

  get isRtpMinInvalid() {
    return this.invalidRtpMin.length > 0;
  }

  get isRtpMaxInvalid() {
    return this.invalidRtpMax.length > 0;
  }

  get columnsProducers() {
    return [
      {key: 'symbol', filter: true, sorter: true},
      {key: 'name', filter: true, sorter: true},
    ]
  }

  get columnsGameTypes() {
    return [
      {key: 'symbol', filter: true, sorter: true},
      {key: 'description', filter: true, sorter: true},
    ]
  }

  get logoAsImage() {
    return this.gameToAddOrModify.logo
  }

  protected mounted() {
    this.loadCompanies();
    this.loadGameTypes();
    emitter.on('reset-game-editor', this.initGame);
  }

  private initGame() {
    (this.$refs.symbolLookup as SymbolLookup).resetFilter();
    this.gameToModify = this.value;
    this.logoFile = null;
    this.symbolFocus = '1';
    this.companyFocus = '1';
    this.resetSymbolLookup()
    if (this.isNewGame) {
      this.gameToAddOrModify = new Game();
      this.gameToAddOrModify.rtpValues = [];
    } else {
      Object.assign(this.gameToAddOrModify, this.gameToModify);
      GameSymbolService.getSymbolSuggestions(this.gameToModify.id)
          .then((suggestions) => {
            this.suggestions = suggestions.items;
          });
    }
  }

  private resetSymbolLookup() {
    (this.$refs.symbolLookup as SymbolLookup).reset();
  }

  private loadCompanies() {
    ProducerService.getAllCompanies()
        .then((data) => this.availableProducers = data.items)
        .catch(() => this.availableProducers = []);
  }

  private loadGameTypes() {
    GameTypeService.getAllGameTypes()
        .then((data) => {
          this.availableGameTypes = data.items;
          this.onGameType();
        })
        .catch(() => this.availableGameTypes = []);
  }

  private resetGame() {
    this.$emit('reset');
    this.onValue();
  }

  private saveGame(initAfterSaving = false) {

    if (this.isNewGame) {
      GameService.addGame(this.gameToAddOrModify)
          .then(() => {
            if (initAfterSaving) {
              this.initGame();
            }
            this.$emit('saved', !initAfterSaving);
          })
          .catch((error) => {
            this.handleErrorResponse(error);
          });
    } else {
      GameService.updateGame(this.gameToAddOrModify)
          .then(() => {
            if (initAfterSaving) {
              this.initGame();
            }
            this.$emit('saved', !initAfterSaving);
          })
          .catch((error) => {
            this.handleErrorResponse(error);
          });
    }
  }

  private doesAttributesChanged(originalValue: Game, currentValue: Game) {
    return !originalValue && currentValue && currentValue.name
        || originalValue && currentValue
        && (originalValue.symbol !== currentValue.symbol || originalValue.secondarySymbol !== currentValue.secondarySymbol
            || originalValue.tertiarySymbol !== currentValue.tertiarySymbol || originalValue.name !== currentValue.name
            || originalValue.company !== currentValue.company || originalValue.developer !== currentValue.developer
            || originalValue.rtpValues !== currentValue.rtpValues || originalValue.rtpMin !== currentValue.rtpMin
            || originalValue.gameType !== currentValue.gameType || originalValue.rtpMax !== currentValue.rtpMax
            || originalValue.notes !== currentValue.notes || originalValue.lastUpdate !== currentValue.lastUpdate
            || originalValue.link !== currentValue.link || originalValue.maxWin !== currentValue.maxWin
            || originalValue.released !== currentValue.released || originalValue.releasedInfo !== currentValue.releasedInfo
            || originalValue.logo !== currentValue.logo);
  }

  private handleErrorResponse(error: any) {

    this.invalidName = '';
    this.invalidSymbol = '';
    this.invalidCompany = '';

    if (error.response && error.response.data) {

      if (error.response.data.errors) {
        const errors = error.response.data.errors;
        for (let i = 0; i < errors.length; i++) {
          const field = errors[i].field;
          const message = errors[i].defaultMessage;
          if (field === 'name') {
            this.invalidName = message;
          }
          if (field === 'symbol') {
            this.invalidSymbol = message;
          }
          if (field === 'secondarySymbol') {
            this.invalidSecondarySymbol = message;
          }
          if (field === 'tertiarySymbol') {
            this.invalidTertiarySymbol = message;
          }
          if (field === 'company') {
            this.invalidCompany = message;
          }
          if (field === 'gameType') {
            this.invalidGameType = message;
          }
        }
      } else if (error.response.data.message) {
        this.invalidSymbol = error.response.data.message;
      }
    }
  }

  private closeAttributesChangedModal(saveChanges?: boolean) {

    if (saveChanges === undefined) {
      this.showAttributesChangedModal = false;
      return;
    } else if (saveChanges) {
      this.saveGame(true)
    } else {
      this.initGame();
    }
    this.showAttributesChangedModal = false;
  }

  private onCompanySelection(company: Company) {
    if (this.companyFocus == '2') {
      this.gameToAddOrModify.developer = company.symbol;
    } else {
      this.gameToAddOrModify.company = company.symbol;
    }
  }

  private addRtpValue() {
    if (this.rtpValueToAdd > 0) {
      this.gameToAddOrModify.rtpValues.push(this.rtpValueToAdd)
      this.rtpValueToAdd = 0;
    }
  }

  private deleteRtpValue() {
    if (this.selectedRtpValue >= 0 && this.gameToAddOrModify.rtpValues.length > this.selectedRtpValue) {
      this.gameToAddOrModify.rtpValues.splice(this.selectedRtpValue, 1);
    }
  }

  private handleLogo(e: any) {
    const selectedImage = e.target.files[0]
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    reader.onload = () => {
      that.gameToAddOrModify.logo = reader.result as string || '';
    }
    reader.readAsDataURL(selectedImage);
    // reader.readAsBinaryString(selectedImage);
  }

  private onSymbolSelection(symbol: string) {
    switch(this.symbolFocus) {
      case '2':
        this.gameToAddOrModify.secondarySymbol = symbol;
        break;
      case '3':
        this.gameToAddOrModify.tertiarySymbol = symbol;
        break;
      default:
        this.gameToAddOrModify.symbol = symbol;
    }
  }

  private gotoLink() {
    if (!this.gameToAddOrModify.link) {
      return;
    }
    let linkUrl = this.gameToAddOrModify.link;
    if (!(linkUrl.startsWith('http://') || linkUrl.startsWith('https://'))) {
      linkUrl = 'http://' + linkUrl;
    }
    window.open(linkUrl);
  }

  @Watch('gameToAddOrModify.name')
  private onName() {
    this.invalidName = '';
  }

  @Watch('gameToAddOrModify.company')
  private onCompany() {
    this.invalidCompany = '';
  }

  @Watch('gameToAddOrModify.developer')
  private onDeveloper() {
    this.invalidDeveloper = '';
  }

  @Watch('gameToAddOrModify.gameType')
  private onGameType() {
    this.invalidGameType = '';
    if (!this.gameToAddOrModify.gameType) {
      this.filteredGameTypes = this.availableGameTypes;
    } else {
      const filterChars = [...this.gameToAddOrModify.gameType.toUpperCase()];
      this.filteredGameTypes = this.availableGameTypes.filter(gameType => filterChars.every((char) => [...gameType.symbol].indexOf(char) >= 0));
    }
  }

  @Watch('gameToAddOrModify.released')
  private onReleased() {
    this.invalidReleased = '';
  }

  @Watch('gameToAddOrModify.link')
  private onLink() {
    this.invalidLink = '';
  }

  @Watch('gameToAddOrModify.maxWin')
  private onMaxWin() {
    this.invalidMaxWin = '';
  }

  @Watch('gameToAddOrModify.lastUpdate')
  private onLastUpdate() {
    this.invalidLastUpdate = '';
  }

  @Watch('value')
  private onValue() {
    this.showAttributesChangedModal = this.doesAttributesChanged(this.gameToModify, this.gameToAddOrModify);
    if (this.showAttributesChangedModal) {
      return;
    }
    this.initGame();
  }

  @Watch('selectedGameType')
  private onSelectedGameType() {
    this.gameToAddOrModify.gameType = this.filteredGameTypes[+this.selectedGameType].symbol;

  }

  onChange(event: any) {
    this.logoFile = event.target.files[0]
    if (this.logoFile) {
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      reader.onload = () => {
        that.gameToAddOrModify.logo = reader.result as string || '';
      }
      reader.readAsDataURL(this.logoFile);
    }
  }

  remove() {
    this.logoFile = null;
    this.gameToAddOrModify.logo = '';
  }

  dragover(event: any) {
    event.preventDefault();
    event.currentTarget.classList.add('logoUploadHover');
    event.currentTarget.classList.remove('logoUpload');
  }

  dragleave(event: any) {
    this.logoUploadHover = false;
    event.currentTarget.classList.add('logoUpload');
    event.currentTarget.classList.remove('logoUploadHover');
  }

  drop(event: any) {
    event.preventDefault();
    event.currentTarget.classList.add('logoUpload');
    event.currentTarget.classList.remove('logoUploadHover');
    this.logoUploadHover = false;
    this.logoFile = [...event.dataTransfer.files][0];
    if (this.logoFile) {
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      reader.onload = () => {
        that.gameToAddOrModify.logo = reader.result as string || '';
      }
      reader.readAsDataURL(this.logoFile);
    }
  }


}
