import axios from 'axios';
import store from "@/store";
import router from "@/router";
import {baseUrl} from "@/services/Commons";

export type UserRole = 'APPLICATION_ADMINISTRATOR' | 'APPLICATION_MANAGER' | 'COMPANY_ADMINISTRATOR' | 'COMPANY_MANAGER' | 'USER';

export default {

    login(credentials: any) {
        return axios
            .post(baseUrl + 'authenticate', credentials)
            .then(response => {
                const token = response.data.token;
                const refreshToken = response.data.refreshToken;
                const role = response.data.role as UserRole;
                const company = response.data.company as string;
                store.dispatch('login', { token, refreshToken, role, company, user: credentials.username }).then(() => {
                    router.push('/dashboard')
                }) ;
            });
    },
    signUp(signUpParameters: any) {
        return axios
            .post(baseUrl + 'sign-up', signUpParameters)
            .then(response => response.data);
    },
    logout() {
        return axios
            .post(baseUrl + 'signout')
            .then(() => {
                store.dispatch('logout');
            })
            .catch(() => store.dispatch('logout'));
    },

    refreshToken(refreshToken: string) {
        const refreshTokenRequest = {
            refreshToken,
        }
        return axios
            .post(baseUrl + 'refreshtoken', refreshTokenRequest)
            .then((response) => {
                const token = response.data.accessToken;
                const refreshToken = response.data.refreshToken;
                store.dispatch('refreshToken', { token, refreshToken});
            })
            .catch(() => store.dispatch('logout'));
    },

    sendPasswordResetTokenRequest(username: string) {
        return axios.get(baseUrl + 'password/reset-token/' + username);
    },

    sendPasswordResetRequest(passwordResetDto: any) {
        return axios.post(baseUrl + 'password/reset', passwordResetDto);
    },

    changePassword(passwordChangeDto: any) {
        return axios.post(baseUrl + 'password/change', passwordChangeDto);
    }

};