
import AppBreadcrumb from './AppBreadcrumb.vue'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt.vue'
import { logo } from '@/assets/brand/logo'
import AuthenticationService from "@/services/AuthenticationService";
import router from "@/router";
import {watch} from "vue";
import store from "@/store";
import Axios from "axios";
import {useStore} from "vuex";
import emitter from "@/main";

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    return {
      logo,
    }
  },
  methods: {
    logout() {
      AuthenticationService.logout()
          .then(() => {
            router.push('/pages/login');
          })
          .catch(() => router.push('/pages/login'));
    },
    onSettings() {
      emitter.emit('open-settings');
    }
  }
}
