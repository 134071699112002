
export class Game {
    id!: number;
    symbol!: string;
    secondarySymbol!: string;
    tertiarySymbol!: string;
    name!: string;
    company!: string;
    developer!: string
    gameType!: string;
    link!: string;
    maxWin!: number;
    released!: string;
    releasedInfo!: string;
    notes!: string;
    lastUpdate!: string;
    rtpValues: number[] = [];
    rtpMin!: number;
    rtpMax!: number;
    logo!: string;
}

export class GameInfo {
    id!: number;
    symbol!: string;
    name!: string;
    company!: string;
    developer!: string
    link!: string;
}