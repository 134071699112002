import axios from 'axios';
import {GameType} from "@/api/gameType";
import {baseUrl} from "@/services/Commons";

// const url = 'http://spielomat.ddns.net:8080/gametype';
const url = baseUrl + 'gametype';

export default {

    getAllGameTypes() {
        return axios
            .get(url)
            .then(response => response.data);
    },
    addGameType(gameType: GameType) {
        return axios
            .post(url, gameType)
            .then(response => response.data);
    },
    updateGameType(gameType: GameType) {
        return axios
            .put(url + '/' + gameType.symbol, gameType)
            .then(response => response.data);
    },
    deleteGameType(symbol: string) {
        return axios
            .delete(url + '/' + symbol);
    },
};