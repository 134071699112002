import {createStore} from 'vuex'
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

const defaultState = {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',
    token: '',
    refreshToken: '',
    user: '',
    role: null,
    company: null,
};

export default createStore({
    state: defaultState,
    plugins: [createPersistedState()],
    getters: {
        isLoggedIn: state => {
            if (state.token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
            }
            return state.token.length > 0;
        },
        getUser: state => {
            return state.user;
        },
        getRole: state => {
            return state.role;
        },
        getCompany: state => {
            return state.company;
        },
        getRefreshToken: state => {
            return state.refreshToken;
        },
    },
    mutations: {
        toggleAside(state) {
            state.asideVisible = !state.asideVisible
        },
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleTheme(state, payload) {
            state.theme = payload.value
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
            state.refreshToken = refreshToken;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        SET_ROLE: (state, role) => {
            state.role = role;
        },
        SET_COMPANY: (state, company) => {
            state.company = company;
        },
        RESET: state => {
            Object.assign(state, defaultState);
        }
    },
    actions: {
        login: ({commit, dispatch}, {token, refreshToken, role, company, user}) => {
            commit('SET_TOKEN', token);
            commit('SET_REFRESH_TOKEN', refreshToken);
            commit('SET_ROLE', role);
            commit('SET_USER', user);
            commit('SET_COMPANY', company);
            // set auth header
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        logout: ({commit}) => {
            commit('RESET', '');
        },
        refreshToken: ({commit, dispatch}, {token, refreshToken}) => {
            commit('SET_TOKEN', token);
            commit('SET_REFRESH_TOKEN', refreshToken);
            // set auth header
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
    },
    modules: {},
})
