import axios from 'axios';
import {Game} from '@/api/game';
import {baseUrl} from '@/services/Commons';

// const url = 'http://spielomat.ddns.net:8080/game';
const url = baseUrl + 'game';

export default {

    getAllGames() {
        return axios
            .get(url)
            .then(response => response.data);
    },
    getAllGameInfo() {
        return axios
            .get(url + '/info')
            .then(response => response.data);
    },
    getGame(id: number) {
        return axios
            .get(url + '/' + id)
            .then(response => response.data);
    },
    addGame(game: Game) {
        return axios
            .post(url, game)
            .then(response => response.data);
    },
    updateGame(game: Game) {
        return axios
            .put(url + '/' + game.id, game)
            .then(response => response.data);
    },
    deleteGame(id: number) {
        return axios
            .delete(url + '/' + id);
    },
    showAvailableSymbols(symbolStartsWith: string) {
        return axios
            .get(url + '/available-symbols?symbolStartsWith=' + symbolStartsWith)
            .then(response => response.data);
    },
};