import axios from 'axios';
import {Game} from '@/api/game';
import {baseUrl} from '@/services/Commons';

// const url = 'http://spielomat.ddns.net:8080/game';
const url = baseUrl + 'game-symbol';

export default {

    // getAllGames() {
    //     return axios
    //         .get(url)
    //         .then(response => response.data);
    // },
    // getAllGameInfo() {
    //     return axios
    //         .get(url + '/info')
    //         .then(response => response.data);
    // },
    // getGame(id: number) {
    //     return axios
    //         .get(url + '/' + id)
    //         .then(response => response.data);
    // },
    addPremiumSymbol(symbol: string) {
        return axios
            .post(url + '/premium/' + symbol)
            .then(response => response.data);
    },
    addBlacklistSymbol(symbol: string) {
        return axios
            .post(url + '/blacklist/' + symbol)
            .then(response => response.data);
    },
    deleteGameSymbol(symbol: string) {
        return axios
            .delete(url + '/' + symbol);
    },
    showAvailableSymbols(symbolStartsWith: string) {
        return axios
            .get(url + '/available-symbols?symbolStartsWith=' + symbolStartsWith)
            .then(response => response.data);
    },

    getSymbolSuggestions(gameId: number) {
        return axios
            .get(url + '/suggestion/' + gameId)
            .then(response => response.data);
    },

    getSymbolBlacklist() {
        return axios
            .get(url + '/blacklist')
            .then(response => response.data);
    },

    getSymbolPremiumList() {
        return axios
            .get(url + '/premium')
            .then(response => response.data);
    }
};