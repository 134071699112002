import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/companies',
        name: 'Companies',
        component: () =>
            import(/* webpackChunkName: "producers" */ '@/views/Companies.vue'),
      },
      {
        path: '/company',
        name: 'My Company',
        component: () =>
            import(/* webpackChunkName: "producers" */ '@/views/MyCompany.vue'),
      },
      {
        path: '/users',
        name: 'Users',
        component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
      },
      {
        path: '/games',
        name: 'Games',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "games" */ '@/views/GameList.vue'),
      },
      {
        path: '/gametypes',
        name: 'GamesTypes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "gametypes" */ '@/views/GameTypes.vue'),
      },
      {
        path: '/game-symbols',
        name: 'Game symbol lists',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "gametypes" */ '@/views/SymbolLists.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/PageNotFound.vue'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/PageInternalServerError.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/UserLogin.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/UserRegister.vue'),
      },
      {
        path: 'forget-password',
        name: 'Forgot Password',
        component: () => import('@/views/ForgotPassword.vue'),
      },
      {
        path: 'password/:action',
        name: 'Password',
        props: true,
        component: () => import('@/views/ResetPassword.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/pages/login', '/pages/forget-password', '/pages/password/reset', '/pages/password/set'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !store.getters.isLoggedIn) {
    return '/pages/login';
  }
  if (!authRequired && store.getters.isLoggedIn) {
    return '/';
  }
});


export default router
